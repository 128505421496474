import { useEffect, useState } from 'react';

import { IconPlusButton, PrimaryButton, SecondaryButton, Select, IconUpdateButton } from '@/components';

import { Link, useLocation } from 'react-router-dom';
import { useLoadCompaniesLessons } from '../hooks/useLoadCompaniesLessons';
import { AppStore } from '@/redux';
import { useDispatch, useSelector } from 'react-redux';
import { SmallSpinner } from '@/components/Spinners/Spinner';
import { useLoadParts } from '../hooks/useLoadParts';

import { Part, Play } from '../models/parts.model';
import cn from 'classnames';
import { FormPartEdit } from '../views/FormPartEdit';
import { ToggleSwitch } from '@/components/Inputs/ToggleSwitch';
import { setSelectModule } from '@/redux/states/lessons/lessons.slice';
import { FormFinalPartEdit } from '../views/FormFinalPartEdit';
import { t } from 'i18next';
import { PARTS_TYPES } from '@/constants';
import { ReactSortable } from "react-sortablejs";
import toast from 'react-hot-toast';
import { FaSort } from 'react-icons/fa'
import Swal from 'sweetalert2'

interface BoxProps {
  data: Part;
  showPart: () => void;
  edit: () => void;
  delete: () => void;
}

const Box: React.FC<BoxProps> = ({ data, showPart, edit, delete: deletePart }) => {
  let options: number = 0;
  options = data.play_id !== null ? options + 1 : options;
  options = data.quiz !== null ? options + 1 : options;
  return (
    <div className='rounded-lg grid grid-rows-1 grid-cols-12 m-4 bg-functional-l-l p-3 justify-items-center items-center'>
      <FaSort />
      {data.is_root || data.type === 'final' ? (
        <PrimaryButton title={data.title} className='col-span-3 w-10/12 justify-seft-center truncate px-2'>
          {data.title}
        </PrimaryButton>
      ) 
      : data.type === 'survey' ? (
        <SecondaryButton
          title={data.survey.title}
          className='col-span-3 w-10/12 justify-seft-center bg-[#BABEC1] truncate px-2'>
          {data.survey.title}
        </SecondaryButton>
      ): (
        <SecondaryButton
        title={data.title}
        className='col-span-3 w-10/12 justify-seft-center bg-[#BABEC1] truncate px-2'>
        {data.title}
      </SecondaryButton>
      )}
      <span className='material-symbols-outlined'>
        {data.type === PARTS_TYPES.VIDEO ? 'videocam' : 'description'}
      </span>
      <div className='col-span-4 justify-self-start'>
        <div className='flex'>
          <p className='mx-2'>
            {t('generics.type')}: {data.type}
          </p>
          <p className='mx-2'>
            {t('generics.options')}: {options}
          </p>
        </div>
      </div>
      <button onClick={edit} className='justify-self-end'>
        <span className='material-symbols-outlined'>edit_square</span>
      </button>
      <button
        disabled={data?.type === 'final' || data.is_root}
        onClick={deletePart}
        className={'justify-self-center disabled:cursor-no-drop'}>
        <span className='material-symbols-outlined'>delete</span>
      </button>
      {
        data.type === PARTS_TYPES.VIDEO && (
          <span onClick={showPart} className='material-symbols-outlined justify-self-center cursor-pointer'>
            expand_more
          </span>
        )
      }
    </div>
  );
};

const BoxAnswers = ({ name, nextPart }: { name: string; label?: number; nextPart: string }) => {
  const isCorrect = name === 'Correcto';
  return (
    <div className='rounded-lg grid grid-rows-1 grid-cols-12 m-4 bg-functional-l-l p-3 justify-items-center items-center'>
      <div></div>
      <SecondaryButton className='col-span-4 w-3/5 justify-seft-end'>
        <div className='flex justify-center items-center'>
          <span className={`material-symbols-outlined ${isCorrect ? 'text-success-m' : 'text-error-m'} mx-2`}>
            {isCorrect ? 'check_circle' : 'cancel'}
          </span>
          {name}
        </div>
      </SecondaryButton>
      <span className='material-symbols-outlined'>format_list_bulleted</span>
      <div className='col-span-4 justify-self-start'>
        <div className='flex'>
          <p className='mr-2'>{`${t('pages.lessons.nextStep')}: ${nextPart}`}</p>
        </div>
      </div>
      <div></div>
      <span className='material-symbols-outlined justify-self-center'>radio_button_unchecked</span>
    </div>
  );
};

const BoxQuestions = ({
  countAnswer,
  isShow,
  showAnswers
}: {
  countAnswer: number;
  showAnswers: () => void;
  isShow: boolean;
}) => {
  return (
    <div className='rounded-lg grid grid-rows-1 grid-cols-12 m-4 bg-functional-l-l p-3 justify-items-center items-center'>
      <div></div>
      <SecondaryButton className='col-span-3 w-10/12 justify-self-start bg-[#BABEC1]'>Quiz</SecondaryButton>
      <span className='material-symbols-outlined'>format_list_bulleted</span>
      <div className='col-span-6 justify-self-start'>
        <p>{t('pages.lessons.quizTile')}</p>
        <div className='flex'>
          <p className='mr-2'>{t('generics.type')}: Quiz</p>
          <p className='mx-2'> {`${t('generics.answers')}: ${countAnswer}`}</p>
        </div>
      </div>
      <span onClick={() => showAnswers()} className='cursor-pointer material-symbols-outlined justify-self-center'>
        {isShow ? 'expand_less' : 'expand_more'}
      </span>
    </div>
  );
};

const BoxReward = ({ play }: { label?: number; play?: Play }) => {
  return (
    <div className='rounded-lg grid grid-rows-1 grid-cols-12 m-4 bg-functional-l-l p-3 justify-items-center items-center'>
      <div></div>
      <SecondaryButton className='col-span-3 w-10/12 justify-self-start bg-[#BABEC1]'>
        {t('generics.awards')}
      </SecondaryButton>
      <span className='material-symbols-outlined'>emoji_events</span>
      <div className='col-span-5 justify-self-start'>
        <p>{`${t('generics.title')}: ${play?.name}`}</p>
        <div className='flex'>
          <p className=''>{`${t('generics.points')}: ${play?.max_points || 0}`}</p>
        </div>
      </div>
      <div></div>
      <span className='material-symbols-outlined justify-self-center'>radio_button_unchecked</span>
    </div>
  );
};

interface PartsTableProps {
  parts: Part[];
  editPart: (slug: string) => void;
  deletePart: (slug: string) => void;
  setParts: (parts: Part[]) => void;
  setWasMovedOrder: (value: boolean) => void;
}

const PartsTable: React.FC<PartsTableProps> = ({ parts, editPart, deletePart, setParts, setWasMovedOrder }) => {
  const [showPart, setShowPart] = useState<number | null>();
  const [showAnswers, setShowAnswers] = useState<boolean>(false);
  const handleEditPart = (slug: string) => {
    editPart(slug);
  };
  const handleDeletePart = (slug: string) => {
    deletePart(slug);
  };

  return (
    <div>
      <ReactSortable list={parts.map(x => ({...x, chosen: true}))} 
      animation={150}
      setList={setParts}
      onChange={() => setWasMovedOrder(true)}
      >
        {parts.map((part) => {
          return (
            <div key={part.id}>
              <Box
                edit={() => handleEditPart(part.slug)}
                showPart={() => {
                  setShowPart((value) => (part.id === value ? null : part.id));
                }}
                data={part}
                delete={() => handleDeletePart(part.slug)}
              />
              {showPart === part.id ? (
                <div className=''>
                  {part.quiz ? (
                    <>
                      <BoxQuestions
                        isShow={showAnswers}
                        countAnswer={part.quiz.options.length}
                        showAnswers={() => setShowAnswers((isShom) => !isShom)}
                      />
                      {showAnswers
                        ? [
                          { name: t('generics.correct'), id: 1 },
                          { name: t('generics.incorrect'), id: 0 }
                        ].map(({ id, name }) => {
                          return (
                            <div key={id}>
                              <BoxAnswers
                                name={name}
                                nextPart={part?.redirections?.[id]?.title || t('generics.noRedirect')}
                              />
                            </div>
                          );
                        })
                        : null}
                    </>
                  ) : null}
                  {part.play_id ? <BoxReward label={part?.id} play={part?.play} /> : null}
                </div>
              ) : null}
            </div>
          );
        })}
      </ReactSortable>
    </div>
  );
};

export const CRUDParts = () => {
  const { state } = useLocation();
  const dispatch = useDispatch();
  const {
    moduleSelected: { slug, name },
    lessonSelected
  } = state;

  const [lessonSlug, setLessonSlug] = useState(lessonSelected);
  const [wasMovedOrder, setWasMovedOrder] = useState(false);
  const { companySlug } = useSelector((state: AppStore) => state.ui);

  const { isLoading: isLoadingLessons, lessons, startLoadLessons } = useLoadCompaniesLessons(companySlug, slug);
  const {
    startDeletePart,
    lessonActive,
    isLoading: isLoadingParts,
    selectPart,
    isLoadingSavePart,
    startLoadParts,
    parts,
    changeStatus,
    status,
    part,
    startResetAll,
    startToggleLesson,
    setLessonActive,
    setParts,
    orderParts,
    isUpdatingOrder
  } = useLoadParts(companySlug, slug, lessonSlug);

  const handleSelectLesson = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setLessonSlug(e.target.value);
  };

  const handleCreatePart = () => {
    changeStatus('creating');
  };

  const handleEditPart = (slugSelected: string) => {
    let part: Part = parts.find(({ slug }: { slug: string }) => slugSelected === slug);
    selectPart(part);
  };

  const handleDeletePart = (slug: string) => {
    startDeletePart(slug);
  };

  const handleToggleLesson = () => {
    startToggleLesson(!lessonActive);
  };

  const defaultLesson = async () => {
    const resp = await startLoadLessons();
    const lesson = resp?.find(({ slug }: { slug: string }) => lessonSelected === slug) ?? { is_active: false };
    const lessonActive = lesson?.is_active ?? true;
    setLessonActive(lessonActive);
  };

  useEffect(() => {
    defaultLesson();
  }, [slug]);

  useEffect(() => {
    if (lessonSlug && part === undefined) {
      startLoadParts();
    }
    return () => {
      startResetAll();
    };
  }, [lessonSlug, isLoadingSavePart]);
  // }, [lessonSlug, isLoadingSavePart])

  const isCreating = status === 'creating';
  const isEditing = status === 'editing';
  const isViewing = status === 'viewing';

  const handleUpdateParts = async() => {
    if (!Array.isArray(parts) || parts.length < 2) {
      return;
    }
    // Validation final part must be the last one
    const partsMapped: Record<string, string | number>[] = []
    if (parts[parts.length - 1].type !== 'final') {
      toast.error(t("pages.lessons.finalPartMustBeLast"))
      return
    }
    parts.forEach((part, index) => {
      partsMapped.push({
        slug: part.slug,
        order: index + 1
      })
    })
    const res = await Swal.fire({
      title: t("pages.lessons.confirmUpdateOrderTitle"),
      icon: 'question',
      text: t("pages.lessons.confirmUpdateOrderDescription"),
      showConfirmButton: true,
      showCancelButton: true,
      confirmButtonText: t("generics.yes"),
      cancelButtonText: t("generics.no"),
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33'
    })
    if (res.isConfirmed) {
      await orderParts(partsMapped)
      setWasMovedOrder(false)
      await startLoadParts()
    }
  }

  return (
    <div className='bg-[#F4F8FE] h-5/6'>
      <div className='bg-functional-el-l sticky top-0 flex items-center justify-between px-14 py-7 mb-4 z-10'>
        <div className='flex justify-center items-center'>
          <Link to='/lecciones' onClick={() => dispatch(setSelectModule(slug))}>
            <span className='material-symbols-outlined mr-3 cursor-pointer border p-2 rounded'>arrow_back</span>
          </Link>
          <div>
            <p className='font-bold text-2xl text-[#1E293B]'>{t('generics.lessons')}</p>
            <p className='font-medium text-[#5F6060]'>{t('pages.lessons.title')}</p>
          </div>
        </div>
        <div className='w-2/3 flex justify-end'>
          <p className='p-4 mx-2 rounded-md truncate w-2/4'>
            <b>{t('pages.lessons.nameModule')}: </b> {name}
          </p>
          <div className='flex w-2/4'>
            <p className='p-4 mx-2 rounded-md truncate'>
              <b>{t('pages.lessons.nameLesson')}:</b>
            </p>
            {!isLoadingLessons ? (
              <Select
                onChange={handleSelectLesson}
                value={lessonSlug}
                className='w-2/4'
                options={lessons}
                optionmap={(value) => value.name}
                optionValueMap={(value) => value.slug}
                optionKeyMap={(value) => `${value.id}-${value.slug}-${value.name}`}
              />
            ) : (
              <SmallSpinner />
            )}
          </div>
        </div>
      </div>
      <div className='flex bg-[#F4F8FE]'>
        <div
          className={cn(' bg-[#00000] w-8/12 h-[79vh] overflow-auto', {
            'w-8/12': isCreating || isEditing,
            'w-full': isViewing
          })}>
          {!isLoadingParts ? (
            <div>
              <div className='flex justify-between items-center'>
                <p className=' m-4 font-bold text-2xl text-[#1E293B]'>{t('pages.lessons.createdSteps')}</p>
                {!isLoadingLessons ? (
                  <div className='mx-10'>
                    <ToggleSwitch
                      onChange={handleToggleLesson}
                      checked={lessonActive}
                      message={t('pages.lessons.activateLesson')}
                    />
                  </div>
                ) : null}
              </div>
              <PartsTable deletePart={handleDeletePart} editPart={handleEditPart} parts={parts} setParts={setParts} setWasMovedOrder={setWasMovedOrder}/>
            </div>
          ) : (
            <SmallSpinner />
          )}
          <div className='w-full flex justify-end p-4'>
            {!isCreating && !isEditing ? (
              <>
                <IconUpdateButton onClick={handleUpdateParts} className={`w-50 disabled:opacity-50 disabled:cursor-not-allowed ${(isUpdatingOrder || !wasMovedOrder) ? "bg-white": "bg-primary-l-m"}  text-gray-800 border border-black`} disabled={isUpdatingOrder || !wasMovedOrder}>
                  {t('pages.lessons.updateOrder')}
                </IconUpdateButton>
                <IconPlusButton onClick={handleCreatePart} className='bg-white border border-black text-gray-800 w-50'>
                  {t('pages.lessons.addNewStep')}
                </IconPlusButton>
              </>
            ) : (
              <></>
            )}
          </div>
        </div>
        {isCreating || isEditing ? (
          <div className='border-l border-functional-l-l w-4/12 p-4 overflow-auto h-[79vh]'>
            {part?.type !== 'final' ? (
              <FormPartEdit lessonSlug={lessonSlug} />
            ) : (
              <FormFinalPartEdit lessonSlug={lessonSlug} />
            )}
          </div>
        ) : null}
      </div>
    </div>
  );
};
