import { CardCompany } from '../components';
import { useNavigate } from 'react-router-dom';
import { useCompany } from '../hooks/useCompanies';
import { Spinner } from '@/components/Spinners/Spinner';
import { useDispatch } from 'react-redux';
import { setCompany } from '@/redux';
import cn from 'classnames';
import { AddButtonPrimary } from '@/components/Buttons/AddButtons';
import { PaginationView } from '@/components/pagination/Pagination';
import { useLoadConf } from '@/hooks';
import { t } from 'i18next';

export const EmpresasApp: React.FC = () => {
  const { configCompany } = useLoadConf();

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const {
    // Metodos
    nextPage,
    previousPage,
    initialPage,
    finalPage,
    changePage,
    setValue,
    // Valores
    value,
    isLoading,
    companies,
    pagination,
    isSuperAdmin,
  } = useCompany();

  const handleInput = (e: any): void => {
    setValue(e.target.value);
  };

  const handleEditCompany = async (id: string | number) => {
    navigate('/empresas/crud', { state: { companyId: id } });
  };

  const handleCreateCompany = async () => {
    navigate('/empresas/crud');
  };

  const handleLinkModules = async (id: string) => {
    const company =
      companies?.find(({ slug }: { slug: string }) => slug === id) ??
      companies?.[0];
    configCompany(id);
    dispatch(setCompany(company));
    navigate('/modulos');
  };

  const handleLinkDashboard = async (id: string | number) => {
    dispatch(setCompany(id));
    navigate('/dashboard');
  };

  return (
    <>
      {isLoading ? (
        <Spinner />
      ) : (
        <article className="bg-[#F4F8FE] h-full overflow-auto">
          <div className="bg-functional-el-l flex items-center sticky top-0 justify-between px-14 py-5 z-10">
            <div>
              <p className="font-bold text-2xl text-[#1E293B]">
                {t('generics.companies')}
              </p>
              <p className="font-medium text-[#5F6060]">
                {t('pages.companies.subtitle')}
              </p>
            </div>
            <div className="w-1/3 flex justify-end">
              <label htmlFor="simple-search" className="sr-only text-black">
                {t('generics.search')}
              </label>
              <div className="relative">
                <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                  {/* <MagnifyingGlassIcon className="w-5 h-5 text-gray-500" /> */}
                  <span className="material-symbols-outlined text-functional-l-d">
                    search
                  </span>
                </div>
                <input
                  onChange={handleInput}
                  value={value}
                  type="text"
                  id="simple-search"
                  className="bg-functional-l-l rounded-lg block w-52 pl-10 p-2.5 py-4"
                  placeholder={t('generics.search')}
                />
              </div>
              {isSuperAdmin ? (
                <AddButtonPrimary
                  name={t('generics.companies')}
                  onClick={handleCreateCompany}
                />
              ) : null}
            </div>
          </div>
          {/* <div className={cn(`flex flex-wrap p-10 `, { 'gap-x-32': showSideBar, 'gap-x-20': !showSideBar })}> */}
          <div className={cn(`grid grid-cols-4 grid-rows-2 px-8`)}>
            {companies.map((empresa: any) => {
              // return <div key={empresa.id} onClick={() => handleEditCompany(empresa.slug)}>
              return (
                <div
                  key={empresa.id}
                  className="flex justify-center items-center"
                >
                  <CardCompany
                    {...{
                      ...empresa,
                      config: () => handleEditCompany(empresa.slug),
                      modules: () => handleLinkModules(empresa.slug),
                      dashboard: () => handleLinkDashboard(empresa.slug),
                    }}
                  />
                </div>
              );
            })}
          </div>
          <PaginationView
            {...{
              pagination,
              nextPage,
              previousPage,
              changePage,
              finalPage,
              initialPage,
            }}
          />
        </article>
      )}
    </>
  );
};
