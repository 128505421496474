import { t } from "i18next"
import { useLessons } from "../hooks";
import { AppStore } from "@/redux";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { IconPlusButton, IconUpdateButton } from "@/components";
import { FaArrowsAlt, FaBook, FaEdit } from "react-icons/fa";
import { ReactSortable } from "react-sortablejs";
import Swal from "sweetalert2";

export const LessonsApp = () => {

  const { companySlug } = useSelector((state: AppStore) => state.ui);
  const { modules, moduleSelected, startLoadModules, startLoadLessons, lessons, setLessons, isUpdatingOrder, orderLessons } = useLessons(companySlug);
  const [module, setModule] = useState<Record<string, any> | undefined>(undefined);
  const navigate = useNavigate();
  const [wasMovedOrder, setWasMovedOrder] = useState(false)

  useEffect(() => {
    startLoadModules().catch(returnToModules)
    startLoadLessons().catch(returnToModules)
  }, [])

  useEffect(() => {
    if (!modules || modules.length === 0) return
    if (!moduleSelected || moduleSelected.length === 0) {
      returnToModules()
    }
    const module = modules?.find((m) => m.slug === moduleSelected)
    if (!module) {
      returnToModules()
    }
    setModule(module)
  }, [modules])

  const returnToModules = () => {
    navigate('/modulos')
  }

  const handleCreateLesson = () => {
    if (!module) {
      return
    }
    navigate('/lecciones/crud', { state: { moduleSelected: module.slug, lessonSelected: undefined } });
  }

  const handleUpdateLessons = async () => {
    if (!Array.isArray(lessons) || lessons.length < 2) {
      return
    }
    const lessonsMapped: Record<string, string | number>[] = []

    lessons.forEach((lesson, index) => {
      lessonsMapped.push({
        slug: lesson.slug,
        order: index + 1
      })
    })
    const res = await Swal.fire({
      title: t("pages.lessons.confirmUpdateOrderTitle"),
      icon: 'question',
      text: t("pages.lessons.confirmUpdateOrderLessonsDescription"),
      showConfirmButton: true,
      showCancelButton: true,
      confirmButtonText: t("generics.yes"),
      cancelButtonText: t("generics.no"),
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33'
    })
    if (res.isConfirmed) {
      await orderLessons(lessonsMapped)
      setWasMovedOrder(false)
      await startLoadLessons()
    }
  }

  const handleSelectLesson = (lessonSlug: string, moduleSlug: string) => {
    navigate('/lecciones/crud', { state: { moduleSelected: moduleSlug, lessonSelected: lessonSlug } });
  };

  const handleParts = (lessonSlug: string) => {
    if (!module) {
      return
    }
    navigate('/lecciones/partes', { state: { moduleSelected: { name: module.name, slug: module.slug }, lessonSelected: lessonSlug } });
  };

  return (
    <>
      <article className='bg-[#F4F8FE] h-auto min-h-full'>
        <div className='bg-functional-el-l flex items-center justify-between px-14 py-7 mb-4'>
          <div>
            <p className='font-bold text-2xl text-[#1E293B]'>{t('generics.lessons')} - <span className="inline-block underline cursor-pointer" onClick={() => navigate('/modulos')}>{module?.name}</span></p>
            <p className='font-medium text-[#5F6060]'>{t('pages.lessons.lessonsDescription')}</p>
          </div>
        </div>
        <div className='px-14 py-4'>
          <div className='flex justify-between gap-2 flex-wrap'>
            <div>
              <p className='font-bold text-2xl text-[#1E293B]'>{t('pages.lessons.lessonsList')}</p>
              <p className='font-medium text-[#5F6060]'>{t('pages.lessons.companyLessons')}</p>
            </div>
            <div className='flex gap-2 items-center justify-center'>
              {
                lessons && lessons.length > 0 && (
                  <IconUpdateButton onClick={handleUpdateLessons} className={`w-50 disabled:opacity-50 disabled:cursor-not-allowed ${(isUpdatingOrder || !wasMovedOrder) ? "bg-white": "bg-primary-l-m"}  text-gray-800 border border-black`} disabled={isUpdatingOrder || !wasMovedOrder}>
                    {t('pages.lessons.updateOrder')}
                  </IconUpdateButton>
                )
               }
              <IconPlusButton onClick={() => handleCreateLesson()} className='bg-white border border-black text-gray-800'>
                {t('generics.lesson')}
              </IconPlusButton>
            </div>
          </div>
        </div>
        <div className="w-full overflow-hidden overflow-x-auto px-14 py-7">
          <div className="w-full flex flex-col overflow-hidden overflow-x-auto bg-white rounded-xl shadow-lg p-10">
            <div className='flex justify-around border-b border-gray-200 pb-2 text-black font-bold mb-4' style={{ minWidth: '1000px' }}>
              <div style={{ width: '30px' }}>
              </div>
              <div className="text-center" style={{ width: '100px' }}>
                Id
              </div>
              <div style={{ width: '400px' }}>
                {t("generics.name")}
              </div>
              <div style={{ width: '100px' }} className='text-center'>
                {t("generics.parts")}
              </div>
              <div style={{ width: '100px' }} className='text-center'>
                {t("generics.status")}
              </div>
              <div style={{ width: '100px' }} className='text-center'>
                {t("generics.actions")}
              </div>
            </div>

            <div className='justify-around flex flex-col gap-4' style={{ minWidth: '1000px' }}>

              {lessons?.length > 0 ? (
                <>
                  {/* If it is necessary to update when an element is moved, please implement the method onChange */}
                  <ReactSortable list={lessons.map((x: any) => ({ ...x, chosen: true }))}
                    setList={setLessons}
                    className='flex flex-col gap-4'
                    animation={150}
                    onChange={() => setWasMovedOrder(true)}
                    ghostClass="bg-primary-l-m">
                    {lessons.map((lesson: any) => {
                      return (
                        <div className='min-w-[1200px] justify-around flex border-b bg-gray-200 pt-4 rounded-2xl pb-4 text-black items-start cursor-pointer'>
                          <div style={{ width: '30px' }} className='text-center flex justify-center items-center'>
                            <FaArrowsAlt className='mt-1' />
                          </div>
                          <div className="text-center" style={{ width: '100px' }}>
                            {lesson.id}
                          </div>
                          <div style={{ width: '400px' }}>
                            {lesson.name}
                          </div>
                          <div style={{ width: '100px' }} className='text-center'>
                            {lesson.parts_count ?? 0}
                          </div>
                          <div style={{ width: '100px' }} className='text-center'>
                            {lesson.is_active ? t("generics.published") : t("generics.notPublished")}
                          </div>
                          <div style={{ width: '100px' }} className='flex justify-center gap-2'>
                            <button type='button' className='w-4 h-4 bg-transparent text-black p-1' title={t("generics.configuration")} value={t("generics.configuration")} onClick={() => null}>
                              <FaEdit className='text-xl' onClick={() => handleSelectLesson(lesson.slug, moduleSelected)} />
                            </button>
                            <button type='button' className='w-4 h-4 bg-transparent text-black p-1' title={t("generics.parts")} value={t("generics.configuration")} onClick={() => null}>
                              <FaBook className='text-xl' onClick={() => handleParts(lesson.slug)} />
                            </button>
                          </div>
                        </div>
                      );
                    })}
                  </ReactSortable>
                </>
              ) : (
                <div className='flex w-full h-96 justify-center items-center'>
                  <p className='font-medium text-3xl'>{t('pages.modules.notModules')}</p>
                </div>
              )}
            </div>
          </div>
        </div>
      </article>
    </>
  )
}