import { useEffect, useState } from 'react';
import { IconPlusButton, PrimaryButton, SecondaryButton, Select } from '@/components';
import { InputText } from '@/components/Inputs/InputText';
import { TextArea } from '@/components/Inputs/TextArea';
import { TitleInput } from '@/components/Inputs/TitleInput';
import { useModal } from '@/hooks/useModal';
import { AppStore } from '@/redux';
import classNames from 'classnames';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useCrudParts } from '../hooks/useCrudParts';
import { ToggleSwitch } from '@/components/Inputs/ToggleSwitch';
import toast from 'react-hot-toast';
import { t } from 'i18next';
import { usePlays } from '../hooks';
import { PARTS_TYPES_OPTIONS, PARTS_TYPES, QUESTION_TYPES_OPTIONS, QUESTION_TYPES } from '@/constants';
import { usePartSurvey } from '../hooks/usePartSurvey';
import { Option } from '@/pages/interactions/interfaces/survey.interface';
interface FormPartEditProps {
  lessonSlug: string;
}

interface UseFormProp {
  type: string;
  description?: string;
  title: string;
  require_auth: boolean;
  is_practical_activity: boolean;
  practical_activity_title: string;
  practical_activity_play_id: number;
  area_id: number
}

const messageRequired = `${t('errorMessage.requiredField')} `;

export const FormPartEdit: React.FC<FormPartEditProps> = ({ lessonSlug }) => {
  const { videoSelected, status, duration, videoIncorrectSelected, part, parts } = useSelector(
    (state: AppStore) => state.parts
  );
  const isEditing = status === 'editing';
  const isCreating = status === 'creating';

  const [disabledButton, setDisabledButton] = useState(false);
  const { companySlug } = useSelector((state: AppStore) => state.ui)
  const [isReward, setIsReward] = useState<boolean>(!!part?.play_id);
  const [isQuiz, setIsQuiz] = useState<boolean>(!!part?.quiz);
  const [isPracticalActivity, setIsPracticalActivity] = useState<boolean>(false);
  const [practicalActivityPlayId, setPracticalActivityPlayId] = useState<number | null>(null);
  const [practicalActivityTitle, setPracticalActivityTitle] = useState<string>('');
  const { deleteQuiz, deleteReward, saveEditedPart, saveCreatePart, changeStatusViewing } = useCrudParts(lessonSlug);
  const { createSurvey, createQuestion, createOption, getSurvey, updateQuestion, deleteOption, updateSurvey } = usePartSurvey(companySlug);
  // const fileId = 17352512

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors }
  } = useForm<UseFormProp>();

  const { startShowModal } = useModal();
  const { loadPlays, plays } = usePlays();

  const onSubmit = async (data: any) => {
    if (!partType || partType === PARTS_TYPES.DEFAULT) {
      setDisabledButton(true);
      toast.error('El tipo de parte es requerido.');
      setDisabledButton(false);
      return;
    }
    if (partType === PARTS_TYPES.VIDEO && isPracticalActivity && !practicalActivityTitle) {
      toast.error('El título de la actividad práctica es requerido.');
      setDisabledButton(false);
      return;
    }
    if (partType === PARTS_TYPES.ENCUESTA && !questionTypeSurvey) {
      toast.error('El tipo de encuesta es requerido.');
      setDisabledButton(false);
      return;
    }
    if (partType === PARTS_TYPES.ENCUESTA && !titleSurvey) {
      toast.error('El título de encuesta es requerido.');
      setDisabledButton(false);
      return;
    }
    if (partType === PARTS_TYPES.ENCUESTA && !questionSurvey) {
      toast.error('La pregunta de la encuesta es requerida.');
      setDisabledButton(false);
      return;
    }
    // Validation options survey are not empty
    let optionsSurveyValues: { id?: number, value: string }[] = [];
    if (partType === PARTS_TYPES.ENCUESTA && (questionTypeSurvey === QUESTION_TYPES.MULTIPLE || questionTypeSurvey === QUESTION_TYPES.UNIQUE)) {
      optionsSurveyValues = optionsSurvey.filter(option => option.value !== '');
    }
    if (partType === PARTS_TYPES.ENCUESTA && (questionTypeSurvey === QUESTION_TYPES.MULTIPLE || questionTypeSurvey === QUESTION_TYPES.UNIQUE) && optionsSurveyValues.length < 2) {
      toast.error('Las opciones de la encuesta son requeridas.');
      setDisabledButton(false);
      return;
    }
    if (isEditing) {
      if (partType !== part.type) {
        toast.error('No se puede cambiar el tipo de parte');
        setDisabledButton(false);
        return;
      }
      if (partType === PARTS_TYPES.ENCUESTA) {
        await handleUpdateSurvey()
        const dataToSend = {
          ...part,
          ...data,
          duration,
          redirections: part.redirections ?? [],
          quiz: []
        }
        await saveEditedPart(dataToSend);
        toast.success('Encuesta actualizada')
        setDisabledButton(false);
      }
      else if (partType === PARTS_TYPES.VIDEO && (videoSelected || part?.video_url)) {
        const dataToSend = {
          ...part,
          ...data,
          ...videoSelected.qualityVideos,
          video_url: videoSelected.link || part?.video_url,
          is_practical_activity: isPracticalActivity,
          practical_activity_play_id: practicalActivityPlayId,
          practical_activity_title: practicalActivityTitle,
          duration,
          redirections: part.redirections ?? [],
          quiz: isQuiz
            ? {
              ...part?.quiz,
              not_correct_video_url: videoIncorrectSelected || part?.quiz?.not_correct_video_url
            }
            : null
        }
        await saveEditedPart(dataToSend);
      } else {
        toast.error(t('pages.lessons.requestedVideos'));
      }
    } else if (isCreating) {
      let dataSurvey: Record<string, unknown> | undefined = {};
      if (partType === PARTS_TYPES.ENCUESTA) {
        // Create survey
        try {
          dataSurvey = await handleCreateSurvey()
        }
        catch (err) {
          console.error('Error creando la encuesta', err)
          toast.error('Error creando la encuesta')
          setDisabledButton(false);
          return;
        }
      }
      if (partType === PARTS_TYPES.ENCUESTA) {
        const dataToSend = {
          ...part,
          ...data,
          title: `Encuesta`,
          duration: duration || '0',
          ...dataSurvey,
          redirections: part?.redirections ?? [],
          is_root: parts.length === 1,
          quiz: []
        }
        await saveCreatePart(dataToSend);
      }
      else if (partType === PARTS_TYPES.VIDEO && videoSelected && (isQuiz ? videoIncorrectSelected : true)) {
        const dataToSend = {
          ...part,
          ...data,
          is_practical_activity: isPracticalActivity,
          practical_activity_play_id: practicalActivityPlayId,
          practical_activity_title: practicalActivityTitle,
          ...videoSelected.qualityVideos,
          video_url: videoSelected.link || part?.video_url,
          duration,
          redirections: part?.redirections ?? [],
          quiz: isQuiz
            ? {
              ...part?.quiz,
              not_correct_video_url: videoIncorrectSelected
            }
            : null,
          is_root: parts.length === 1
        }
        await saveCreatePart(dataToSend);
      } else {
        toast.error(t('pages.lessons.requestedVideos'));
      }
    }
    setDisabledButton(false);
  };

  const handleCreateSurvey = async () => {
    // 1. Create survey
    const surveyData = await createSurvey(titleSurvey)
    if (!surveyData) {
      throw new Error('Error creando la encuesta')
    }
    // 2. Create survey question
    const questionData = await createQuestion(surveyData.slug, {
      question_type: questionTypeSurvey,
      is_required: true,
      question_text: questionSurvey,
    })
    const dataPartSurvey = {
      survey_id: surveyData.id,
    }
    if (!questionData) throw new Error('Error creando la pregunta')
    if (questionTypeSurvey === QUESTION_TYPES.OPEN) return dataPartSurvey
    // 3. Add options
    const optionsSurveyValues: { id?: number, value: string }[] = optionsSurvey.filter(option => option.value !== '');
    const optionsMapped = optionsSurveyValues.map(option => ({ option_text: option.value, is_correct: true }))
    for (const option of optionsMapped) {
      const created = await createOption(surveyData.slug, questionData.id, option)
      if (!created) throw new Error('Error creando la opción')
    }
    return dataPartSurvey
  }

  const handleUpdateSurvey = async () => {
    try {
      if (!part.survey && part.survey.slug) throw new Error('La parte no tiene una encuesta asociada')
      const surveySlug = part.survey.slug
      // 1. Get survey
      const surveyInfo = await getSurvey(surveySlug)
      if (!surveyInfo) throw new Error('Error obteniendo la encuesta')
      if (surveyInfo.title !== titleSurvey) {
        // update title survey
        surveyInfo.title = titleSurvey
        await updateSurvey(surveySlug, surveyInfo)
      }
      // 2. Update the question title
      // 3. Update the question type
      if (!surveyInfo.questions || !Array.isArray(surveyInfo.questions)) throw new Error('Error obteniendo las opciones')
      const question = surveyInfo.questions[0]
      const wasUpdated = await updateQuestion(surveySlug, question.id, {
        question_text: questionSurvey,
        question_type: questionTypeSurvey
      })
      if (!wasUpdated) throw new Error('Error actualizando la pregunta')
      // 4. Update the options
      if (questionTypeSurvey === QUESTION_TYPES.OPEN) {
        // Delete all options
        deleteAllOptions(surveySlug, question.id, surveyInfo.questions[0].options)
        return
      }
      // delete all options
      await deleteAllOptions(surveySlug, question.id, surveyInfo.questions[0].options)
      // Add new options
      const optionsSurveyValues: { id?: number, value: string }[] = optionsSurvey.filter(option => option.value !== '');
      optionsSurveyValues.forEach(async (option) => {
        const created = await createOption(surveySlug, question.id, { option_text: option.value, is_correct: true })
        if (!created) throw new Error('Error creando la opción')
      })
    }
    catch (err) {
      console.error('Error actualizando la encuesta', err)
      toast.error('Error actualizando la encuesta')
    }
  }

  const deleteAllOptions = async (surveySlug: string, questionId: number, options: Option[]) => {
    if (!options || !Array.isArray(options)) throw new Error('Error obteniendo las opciones')
    if (options.length === 0) return
    // Delete all options
    for (const option of options) {
        const wasDeleted = await deleteOption(surveySlug, questionId, option.id)
        if (!wasDeleted) throw new Error('Error eliminando las opciones')
      }
  }

  const handleChooseVideo = (e: React.FormEvent) => {
    e.preventDefault();
    startShowModal({
      title: t('pages.lessons.selectVideo'),
      name: 'ChooseVideo',
      data: { label: 'videoSelected' },
      size: 'w-11/12'
    });
  };

  const handleChooseWrongVideo = (e: React.FormEvent) => {
    e.preventDefault();
    startShowModal({
      title: t('pages.lessons.selectVideo'),
      name: 'ChooseVideo',
      data: { label: 'videoIncorrectSelected' },
      size: 'w-11/12'
    });
  };

  useEffect(() => {
    if (isPracticalActivity) {
      loadPlays();
    }
  }, [isPracticalActivity])

  const handleCancel = (e: React.FormEvent) => {
    e.preventDefault();
    changeStatusViewing();
  };

  const handleRedirection = (e: React.FormEvent) => {
    e.preventDefault();
    startShowModal({
      title: t('pages.lessons.redirections'),
      name: 'Redirection'
    });
  };

  const handleEditQuestion = (e: any) => {
    e.preventDefault();

    if (e.target.checked) {
      startShowModal({
        title: t('pages.lessons.questionForm'),
        name: 'FormQuestions'
      });
    } else {
      deleteQuiz();
    }
  };

  const handleChangeQuestion = (e: any) => {
    e.preventDefault();
    startShowModal({
      title: t('pages.lessons.questionForm'),
      name: 'FormQuestions'
    });
  };

  const handleChangeReward = (e: any) => {
    e.preventDefault();
    startShowModal({
      title: t('pages.lessons.selectAward'),
      name: 'ChoosePlay'
    });
  };

  const handleEditReward = (e: any) => {
    e.preventDefault();
    if (e.target.checked) {
      startShowModal({
        title: t('pages.lessons.selectAward'),
        name: 'ChoosePlay'
      });
    } else {
      deleteReward();
    }
  };

  const partSlug = part?.slug;
  const partType = watch('type', 'default');
  const [questionSurvey, setQuestionSurvey] = useState<string>('');
  const [titleSurvey, setTitleSurvey] = useState<string>('');
  const [questionTypeSurvey, setQuestionTypeSurvey] = useState<string>('');
  const [optionsSurvey, setOptionsSurvey] = useState<{ id?: number, value: string }[]>([{ value: 'Sí' }, { value: 'No' }]);

  useEffect(() => {
    // loadLocalPart(part)
    setValue('type', part?.type);
    setValue('title', part?.title);
    setValue('description', part?.description);
    setValue('require_auth', part?.require_auth ?? false);
    setOptionsSurvey(part?.options_survey ?? [{ value: 'Sí' }, { value: 'No' }]);
    setIsPracticalActivity(!!part?.is_practical_activity);
    setPracticalActivityTitle(part?.practical_activity_title ?? '');
    setPracticalActivityPlayId(part?.practical_activity_play_id ?? null);
    if (part?.type === PARTS_TYPES.ENCUESTA) {
      loadDataSurvey();
    }
  }, [lessonSlug, partSlug]);

  useEffect(() => {
    setIsReward(!!part?.play_id);
  }, [part?.play_id]);

  useEffect(() => {
    setIsQuiz(!!part?.quiz);
  }, [part?.quiz]);

  useEffect(() => {
    setIsPracticalActivity(!!part?.is_practical_activity);
  }, [part?.is_practical_activity]);

  useEffect(() => {
    setPracticalActivityTitle(part?.practical_activity_title ?? '');
    setPracticalActivityPlayId(part?.practical_activity_play_id ?? null);
  }, [part?.practical_activity_title, part?.practical_activity_play_id]);

  const disabled = false;

  const loadDataSurvey = async () => {
    const partSurvey = part?.survey;
    if (!partSurvey || !partSurvey.slug) {
      toast.error('Error cargando la encuesta');
      return;
    }
    const { slug } = partSurvey;
    const survey = await getSurvey(slug)
    if (!survey) return toast.error('Error cargando la encuesta');
    setTitleSurvey(survey.title)
    const question = survey.questions[0];
    if (!question) return toast.error('Error cargando la pregunta');
    setQuestionSurvey(question.question_text);
    setQuestionTypeSurvey(question.question_type);
    const options = question.options;
    setOptionsSurvey(options.map(option => ({ id: option.id, value: option.option_text })));
    if (question.question_type === QUESTION_TYPES.OPEN) return
    if (!Array.isArray(options)) return toast.error('Error cargando las opciones');
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <p title={isEditing ? part.title : t('generics.new')} className=' text-4xl font-bold line-clamp-2'>{`${t(
        'pages.lessons.configStep'
      )} ${isEditing ? part.title : t('generics.new')}`}</p>
      <div className='p-4 w-5/6'>
        <TitleInput>{`${t('pages.lessons.stepType')}*`}</TitleInput>
        <Select
          className='w-full mt-0 disabled:50 disabled:bg-gray-200 disabled:cursor-not-allowed'
          options={[...PARTS_TYPES_OPTIONS]}
          optionmap={(option) => option.label}
          optionValueMap={(option) => option.value}
          defaultValue={'default'}
          register={() => register('type', { required: messageRequired })}
          value={partType}
          disabled={part}
        />
      </div>
      {
        partType === PARTS_TYPES.VIDEO && (
          <>
            <div className='p-4 w-5/6'>
              <TitleInput>Video*</TitleInput>
              <div className='flex flex-col items-center justify-center'>
                <div className={classNames('w-full my-2 h-[200px] row-span-2')}>
                  {/* <img src={isImageLoaded ? URL.createObjectURL(watchImage[0]) : "/imgLayout.png"} alt="" className="row-span-2 w-full h-full" /> */}
                  {/* <img src={image?.image_path ?? "/imgLayout.png"} alt="" className="row-span-2 w-full h-full object-contain" /> */}
                  {videoSelected ? (
                    <video
                      className='row-span-2 w-full h-full object-contain'
                      src={typeof videoSelected === 'string' ? videoSelected : videoSelected.link}></video>
                  ) : (
                    <img src={'/imgLayout.png'} alt='' className='row-span-2 w-full h-full object-contain' />
                  )}
                </div>
                {/* <Select className="self-end" options={['png', 'jpg', 'jpeg']} optionmap={(value) => value} register={() => register('typeImage')} /> */}
                <IconPlusButton onClick={handleChooseVideo} className='w-full'>
                  {t('pages.lessons.selectVideo2')}
                </IconPlusButton>
              </div>
            </div>
            <div className='p-4 w-5/6'>
              <InputText
                className='w-full'
                disabled={disabled}
                register={() => register('title', { required: messageRequired })}
                placeholder={`${t('generics.title')}*`}
              />
              {errors.title ? <p className='text-warning-d'>{errors.title.message}</p> : null}
            </div>
            <div className='p-4 w-5/6'>
              <TextArea
                className='w-full'
                disabled={disabled}
                register={() => register('description')}
                placeholder={t('generics.description')}
              />
              {errors.description ? <p className='text-warning-d'>{errors.description.message}</p> : null}
            </div>
            <div className='p-4 mt-4 w-5/6'>
              <TitleInput>{t('pages.lessons.selectTypeOptions')}</TitleInput>
              <div className='flex justify-between items-center my-2'>
                <TitleInput>Quiz</TitleInput>
                <div className='flex items-center'>
                  {isQuiz ? (
                    <button onClick={handleChangeQuestion} className='justify-self-end mx-4'>
                      <span className='material-symbols-outlined'>edit_square</span>
                    </button>
                  ) : null}
                  <ToggleSwitch onChange={handleEditQuestion} checked={isQuiz} message='' />
                </div>
              </div>
              <hr />
              {isQuiz ? (
                <div className='my-4 '>
                  <div className='flex flex-col items-center justify-center'>
                    <div className={classNames('w-full my-2 h-[200px] row-span-2')}>
                      {/* <img src={isImageLoaded ? URL.createObjectURL(watchImage[0]) : "/imgLayout.png"} alt="" className="row-span-2 w-full h-full" /> */}
                      {/* <img src={image?.image_path ?? "/imgLayout.png"} alt="" className="row-span-2 w-full h-full object-contain" /> */}
                      {videoIncorrectSelected ? (
                        <video
                          className='row-span-2 w-full h-full object-contain'
                          src={
                            typeof videoIncorrectSelected === 'string' ? videoIncorrectSelected : videoIncorrectSelected.link
                          }></video>
                      ) : (
                        <img src={'/imgLayout.png'} alt='' className='row-span-2 w-full h-full object-contain' />
                      )}
                    </div>
                    {/* <Select className="self-end" options={['png', 'jpg', 'jpeg']} optionmap={(value) => value} register={() => register('typeImage')} /> */}
                    <IconPlusButton onClick={handleChooseWrongVideo} className='w-full'>
                      {t('pages.lessons.incorrectVideo')}
                    </IconPlusButton>
                  </div>
                </div>
              ) : null}
              {/* <label className="label cursor-pointer "> */}
              {/* <span className="label-text font-bold text-lg">Recompensa</span> */}
              {/* <input type="checkbox" className="checkbox" {...register('isReward')} checked={options?.isReward} /> */}
              {/* <input type="checkbox" className="checkbox" {...register('isReward', {
                        onChange: (e: any) => {
                            handleEditReward(e)
                        }
                    })} /> */}
              {/* <input type="checkbox" className="checkbox" onChange={handleEditReward} checked={isReward} /> */}
              {/* </label> */}
              <div className='flex justify-between items-center my-4'>
                <TitleInput>{t('generics.award')}</TitleInput>
                <div className='flex items-center'>
                  {isReward ? (
                    <button onClick={handleChangeReward} className='justify-self-end mx-4'>
                      <span className='material-symbols-outlined'>edit_square</span>
                    </button>
                  ) : null}
                  <ToggleSwitch onChange={handleEditReward} checked={isReward} message='' />
                </div>
              </div>
              <hr />
              {isReward ? <p className='text-xl my-3'>{`${t('pages.lessons.selectedAward')}: ${part?.play?.name}`}</p> : null}
              <div className='my-4'>
                <p className='py-4 text-xl font-bold'>{`${t('pages.lessons.nextStep')} ${isQuiz ? `(${t('pages.lessons.incorrectAnswer')}) :` : ':'
                  }`}</p>
                <p className='py-4 text-xl '> {part?.redirections?.[0]?.title || ''}</p>
                {isQuiz ? (
                  <>
                    <p className='py-4 text-xl font-bold'>{`${t('pages.lessons.nextStep')} (${t(
                      'pages.lessons.correctAnswer'
                    )}):`}</p>
                    <p className='py-4 text-xl '> {part?.redirections?.[1]?.title || ''}</p>
                  </>
                ) : null}
                <IconPlusButton onClick={handleRedirection} className='w-full my-4'>
                  {t('pages.lessons.selectNextStep')}
                </IconPlusButton>
              </div>
              <div className='flex justify-between items-center my-4'>
                <TitleInput>{t('pages.lessons.RequiresAuthentication')}</TitleInput>
                <ToggleSwitch register={() => register('require_auth')} message='' />
              </div>
              {/* Practical Activity */}
              <div className='flex justify-between items-center my-4'>
                <TitleInput>{t('pages.lessons.practicalActivity')}</TitleInput>
                <ToggleSwitch checked={isPracticalActivity} onChange={(e) => {
                  const value = e.target.checked;
                  setIsPracticalActivity(value);
                }} message='' />
              </div>
              {
                isPracticalActivity && (
                  <>
                    <InputText
                      className='w-full'
                      disabled={disabled}
                      onChange={(e) => setPracticalActivityTitle(e.target.value)}
                      value={practicalActivityTitle}
                      placeholder={`${t('pages.lessons.titlePractical')}*`}
                    />

                    <div className='flex justify-between items-center my-2'>
                      <TitleInput>{t('generics.award')}</TitleInput>
                    </div>
                    {
                      plays && (
                        <Select
                          className='w-full'
                          defaultValue={part?.practical_activity_play_id}
                          options={plays}
                          optionmap={(play) => play.name}
                          optionValueMap={(play) => play.id}
                          onChange={(e) => {
                            setPracticalActivityPlayId(Number(e.target.value))
                          }}
                        />
                      )
                    }
                  </>
                )
              }
            </div>
          </>
        )
      }
      {
        partType === PARTS_TYPES.ENCUESTA && (
          <>
            <div className='p-4 w-5/6'>
              <InputText className='w-full mt-0' value={titleSurvey} onChange={(e) => setTitleSurvey(e.target.value)} placeholder={`${t('pages.lessons.surveyTitle')}*`} />
              <InputText className='w-full mt-0' value={questionSurvey} onChange={(e) => setQuestionSurvey(e.target.value)} placeholder={`${t('pages.lessons.surveyQuestion')}*`} />
              <TitleInput>{`${t('pages.lessons.surveyType')}*`}</TitleInput>
              <Select
                className='w-full'
                defaultValue={'default'}
                options={[...QUESTION_TYPES_OPTIONS]}
                optionmap={(option) => option.label}
                optionValueMap={(option) => option.value}
                value={questionTypeSurvey}
                onChange={(e) => setQuestionTypeSurvey(e.target.value)}
              />
              {
                (questionTypeSurvey === QUESTION_TYPES.UNIQUE || questionTypeSurvey === QUESTION_TYPES.MULTIPLE) && (
                  <>
                    <div className='w-full h-auto mt-3'>
                      {
                        optionsSurvey.map((option, index) => (
                          <div key={index}>
                            <label>{`${t('generics.option')} ${index + 1}:`}</label>
                            <div className='flex'>
                              <input type='text' value={option.value} onChange={(e) => {
                                const newOptions = [...optionsSurvey];
                                newOptions[index].value = e.target.value;
                                setOptionsSurvey(newOptions);
                              }}
                                className='w-full h-12 bg-[#EAEAEA] rounded-lg px-3 mr-2 mt-0'
                              />
                              <button type='button' onClick={() => {
                                if (optionsSurvey.length === 2) {
                                  toast.error('Debe haber al menos dos opciones');
                                  return;
                                }
                                const newOptions = [...optionsSurvey];
                                newOptions.splice(index, 1);
                                setOptionsSurvey(newOptions);
                              }} >
                                <span className='material-symbols-outlined'>delete</span>
                              </button>
                            </div>
                          </div>
                        ))
                      }
                      {
                        optionsSurvey.length < 6 && (
                          <PrimaryButton onClick={() => {
                            const newOptions = [...optionsSurvey];
                            newOptions.push({ value: '' });
                            setOptionsSurvey(newOptions);
                          }}
                            className='block mx-auto mt-3'
                            type='button'>
                            <p>{t('generics.add')}</p>
                          </PrimaryButton>
                        )
                      }

                    </div>
                  </>
                )
              }
              <div className='my-4'>
                <p className='py-4 text-xl font-bold'>{`${t('pages.lessons.nextStep')}`}</p>
                <p className='py-4 text-xl '> {part?.redirections?.[0]?.title || ''}</p>
                <IconPlusButton onClick={handleRedirection} className='w-full my-4'>
                  {t('pages.lessons.selectNextStep')}
                </IconPlusButton>
              </div>
              <div className='flex justify-between items-center my-4'>
                <TitleInput>{t('pages.lessons.RequiresAuthentication')}</TitleInput>
                <ToggleSwitch register={() => register('require_auth')} message='' />
              </div>
              <div className='flex justify-between items-center my-4'>
                <TitleInput>{t('generics.award')}</TitleInput>
                <div className='flex items-center'>
                  {isReward ? (
                    <button onClick={handleChangeReward} className='justify-self-end mx-4'>
                      <span className='material-symbols-outlined'>edit_square</span>
                    </button>
                  ) : null}
                  <ToggleSwitch onChange={handleEditReward} checked={isReward} message='' />
                </div>
              </div>
              {isReward ? <p className='text-xl my-3'>{`${t('pages.lessons.selectedAward')}: ${part?.play?.name}`}</p> : null}
            </div>
          </>
        )
      }

      <div className='flex justify-end'>
        <PrimaryButton disabled={disabledButton} type='submit'>
          {t('generics.save')}
        </PrimaryButton>
        <SecondaryButton className='px-5' onClick={handleCancel}>
          {t('generics.cancel')}
        </SecondaryButton>
      </div>
    </form>
  );
};
