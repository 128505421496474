import { AppStore } from "@/redux"
import { useDispatch, useSelector } from "react-redux"
import { useEffect, useState } from 'react'

import { setIsLoading, loadModules, setError, setResetState } from "@/redux/states/modules/modules.slice"
import { paginationAdapter } from "@/adapters/pagination.adapter"
import { loadModulesAdapter } from "../adapters/loadModules.adapter"
// import { modulesData } from "../data/modules"
import toast from "react-hot-toast"
import useAxiosPrivate from "@/hooks/useApiV2Private"
import { t } from "i18next"
import { apiV3 } from "@/apis/apiV3"


let controller: AbortController, signal
export const useModules = (companySlug: string) => {

    const apiV2 = useAxiosPrivate()
    const dispatch = useDispatch()
    const { isLoading, modules, pagination, per_page } = useSelector((state: AppStore) => state.modules)
    const [isUpdatingOrder, setIsUpdatingOrder] = useState<boolean>(false)

    const startLoadModules = async () => {
        dispatch(setIsLoading())
        try {
            if (controller) {
                controller.abort()
            }
            controller = new AbortController()
            signal = controller.signal
            const resp = await apiV2.get(`/companies/${companySlug}/modules`, {
                params: { per_page: -1 },
                signal
            })
            dispatch(loadModules({ pagination: paginationAdapter(resp.data.pagination), items: loadModulesAdapter(resp.data.items) }))
        } catch (error: any) {
            if (error.response.status === 404) {
                toast.error('Hubo un problema al cargar los módulos, intentelo de nuevo')
            }
        }
    }

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            startLoadModules()
        }, 250)
        return () => {
            clearTimeout(delayDebounceFn)
            dispatch(setResetState())
        }
    }, [])

    const setModules = (modules: any) => {
        dispatch(loadModules({ pagination: paginationAdapter(pagination), items: modules }))
    }

    const orderModules = async (modulesMapped: Record<string, string | number>[]) => {
        try {
            setIsUpdatingOrder(true)
            await apiV3.post(`/companies/${companySlug}/modules/order`, {
                modules: modulesMapped
            })
            toast.success(t("successMessage.modulesOrder"))
        }
        catch (err) {
            console.error('Error:', err)
            toast.error(t("errorMessage.updatingOrderModules"))
        }
        finally {
            setIsUpdatingOrder(false)
        }
    }

    return {
        isLoading,
        modules,
        pagination,
        per_page,
        setError,
        isUpdatingOrder,
        setModules,
        startLoadModules,
        orderModules
    }
}
