import { useNavigate } from 'react-router-dom';
import { useModules } from '../hooks/useModules';
import { AppStore } from '@/redux';
import { useDispatch, useSelector } from 'react-redux';
import { setSelectModule } from '@/redux/states/lessons/lessons.slice';
import { t } from 'i18next';
import { IconPlusButton, IconUpdateButton } from '@/components';
import { FaArrowsAlt, FaBook, FaEdit } from 'react-icons/fa';
import { ReactSortable } from "react-sortablejs";
import Swal from 'sweetalert2'
import { useState } from 'react';

export const ModulosApp = () => {
  const { companySlug } = useSelector((state: AppStore) => state.ui);

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const {
    modules,
    setModules,
    isUpdatingOrder,
    startLoadModules,
    orderModules
  } = useModules(companySlug);

  const handleSelectModule = (id: string | number | undefined) => {
    navigate('/modulos/crud', { state: { moduleSelected: id } });
  };

  const handleLinkLessons = async (id: string | number) => {
    dispatch(setSelectModule(id));
    navigate(`/lecciones`);
  };

  const [wasMovedOrder, setWasMovedOrder] = useState(false)

  const handleUpdateModules = async () => {
    if (!Array.isArray(modules) || modules.length < 2) {
      return
    }
    const modulesMapped: Record<string, string | number>[] = []

    modules.forEach((module, index) => {
      modulesMapped.push({
        slug: module.slug,
        order: index + 1
      })
    })
    const res = await Swal.fire({
      title: t("pages.lessons.confirmUpdateOrderTitle"),
      icon: 'question',
      text: t("pages.lessons.confirmUpdateOrderModulesDescription"),
      showConfirmButton: true,
      showCancelButton: true,
      confirmButtonText: t("generics.yes"),
      cancelButtonText: t("generics.no"),
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33'
    })
    if (res.isConfirmed) {
      await orderModules(modulesMapped)
      setWasMovedOrder(false)
      await startLoadModules()
    }
  }

  return (
    <>
      <article className='bg-[#F4F8FE] h-auto min-h-full'>
        <div className='bg-functional-el-l flex items-center justify-between px-14 py-7 mb-4'>
          <div>
            <p className='font-bold text-2xl text-[#1E293B]'>{t('pages.modules.modulesTitle')}</p>
            <p className='font-medium text-[#5F6060]'>{t('pages.modules.modulesDescription')}</p>
          </div>
        </div>
        <div className='px-14 py-4'>
          <div className='flex justify-between gap-2 flex-wrap'>
            <div>
              <p className='font-bold text-2xl text-[#1E293B]'>{t('pages.modules.modulesList')}</p>
              <p className='font-medium text-[#5F6060]'>{t('pages.modules.companyModules')}</p>
            </div>
            <div className='flex gap-2 items-center justify-center'>
              {
                modules && modules.length > 0 && (
                  <IconUpdateButton onClick={handleUpdateModules} className={`w-50 disabled:opacity-50 disabled:cursor-not-allowed ${(isUpdatingOrder || !wasMovedOrder) ? "bg-white": "bg-primary-l-m"}  text-gray-800 border border-black`} disabled={isUpdatingOrder || !wasMovedOrder}> 
                    {t('pages.lessons.updateOrder')}
                  </IconUpdateButton>
                )
              }
              <IconPlusButton onClick={() => handleSelectModule(undefined)} className='bg-white border border-black text-gray-800'>
                {t('generics.module')}
              </IconPlusButton>
            </div>
          </div>
        </div>

        <div className="w-full overflow-hidden overflow-x-auto px-14 py-7">
          <div className="w-full flex flex-col overflow-hidden overflow-x-auto bg-white rounded-xl shadow-lg p-10">
            <div className='flex justify-around border-b border-gray-200 pb-2 text-black font-bold mb-4' style={{ minWidth: '1000px' }}>
              <div style={{ width: '30px' }}>
              </div>
              <div className="text-center" style={{ width: '80px' }}>
                Id
              </div>
              <div style={{ width: '300px' }}>
                {t("generics.name")}
              </div>
              <div style={{ width: '100px' }} className='text-center'>
                {t("generics.areas")}
              </div>
              <div style={{ width: '100px' }} className='text-center'>
                {t("generics.lessons")}
              </div>
              <div style={{ width: '100px' }} className='text-center'>
                {t("generics.registered")}
              </div>
              <div style={{ width: '100px' }} className='text-center'>
                {t("generics.certificate")}
              </div>
              <div style={{ width: '100px' }} className='text-center'>
                {t("generics.status")}
              </div>
              <div style={{ width: '100px' }} className='text-center'>
                {t("generics.actions")}
              </div>
            </div>

            <div className='justify-around flex flex-col gap-4' style={{ minWidth: '1000px' }}>

              {modules?.length > 0 ? (
                <>
                  {/* If it is necessary to update when an element is moved, please implement the method onChange */}
                  <ReactSortable list={modules.map((x: any) => ({ ...x, chosen: true })) }
                    setList={setModules}
                    className='flex flex-col gap-4'
                    animation={150}
                    ghostClass='bg-primary-l-m'
                    onChange={() => setWasMovedOrder(true)}
                    >
                    {modules.map((module: any) => {
                      return (
                        <div className='min-w-[1200px] justify-around flex border-b bg-gray-200 pt-4 rounded-2xl pb-4 text-black items-start cursor-pointer'>
                          <div style={{ width: '30px' }} className='text-center flex justify-center items-center'>
                            <FaArrowsAlt className='mt-1' />
                          </div>
                          <div className="text-center" style={{ width: '80px' }}>
                            {module.id}
                          </div>
                          <div style={{ width: '300px' }}>
                            {module.name}
                          </div>
                          <div style={{ width: '100px' }} className='text-center'>
                            {module.companyAreas?.length ?? 0}
                          </div>
                          <div style={{ width: '100px' }} className='text-center'>
                            {module.lessonsCount ?? 0}
                          </div>
                          <div style={{ width: '100px' }} className='text-center'>
                            {module.registered ?? 0}
                          </div>
                          <div style={{ width: '100px' }} className='text-center'>
                            {module.hasCertificate ? t("generics.yes") : t("generics.no")}
                          </div>
                          <div style={{ width: '100px' }} className='text-center'>
                            {module.isActive ? t("generics.published") : t("generics.notPublished")}
                          </div>
                          <div style={{ width: '100px' }} className='flex justify-center gap-2'>
                            <button type='button' className='w-4 h-4 bg-transparent text-black p-1' title={t("generics.configuration")} value={t("generics.configuration")} onClick={() => handleSelectModule(module.slug)}>
                              <FaEdit className='text-xl' />
                            </button>
                            <button type='button' className='w-4 h-4 bg-transparent p-1 text-black' title={t("generics.lessons")} value={t("generics.lessons")} onClick={() => handleLinkLessons(module.slug)} >
                              <FaBook className='text-xl' />
                            </button>
                          </div>
                        </div>
                      );
                    })}
                  </ReactSortable>
                </>
              ) : (
                <div className='flex w-full h-96 justify-center items-center'>
                  <p className='font-medium text-3xl'>{t('pages.modules.notModules')}</p>
                </div>
              )}
            </div>
          </div>
        </div>
      </article>
    </>
  );
};
