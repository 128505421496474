import { Navigate, Route, Routes } from "react-router-dom"
import { LessonsApp } from "../pages/LessonsApp"
import { CRUDLesson } from "../pages/CRUDLesson"
import { CRUDParts } from "../pages/CRUDParts"


export const LessonsRoutes = () => {
    return (
        <Routes>
            <Route path="" element={<LessonsApp />}  />
            <Route path="crud" element={<CRUDLesson />} />
            <Route path="partes" element={<CRUDParts />} />
            <Route path="/*" element={<Navigate to='/lecciones' />} />
        </Routes>
    )
}
